<template>
  <div class="text-center">
    <v-dialog v-model="isVisible" width="350px">
      <v-card id="modal-c-c">
        <v-card-text class="text-center pt-5 MainTitle">
          <div class="container-header">
            <div class="container-timer">
              <circular-count-down-timer
                :circles="timerSetup.circles"
                :main-circle-id="timerSetup.mainCircleId || '1'"
                :stop-conditions="timerSetup.stopConditions"
                :size="timerSetup.size"
                @update="updated"
                ref="countdown"
                v-if="isTimerVisible"
              />
            </div>
            <button @click="onClose" class="container-header__icon">
              <v-icon size="30px">mdi-close</v-icon>
            </button>
          </div>
          <span class="pt-3 pb-5 d-block">
            Confirmación del llamado para {{ name ? name.replaceAll(/\s/g,'') == '' ? '' : `${name} con` : '' }} la cédula
            {{ id }}
          </span>
          <v-btn color="primary" block @click="onConfirm">Confirmar</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ModalBody",

  data() {
    return {
      isVisible: false,
      isTimerVisible: true,
      timerSetup: {
        stopConditions: { 1: 16 },
        mainCircleId: "1",
        circles: [
          {
            id: "1",
            steps: 16,
            size: 40,
            label: "",
            strokeWidth: 5,
            strokeColor: "#2196f3",
          },
        ],
      },
    };
  },

  props: ["isDisplayModalConfirmCall", "trigger", "name", "id"],

  watch: {
    isVisible(val) {
      if (val) {
        const timer = setTimeout(() => {
          document
            .getElementById("modal-c-c")
            .addEventListener("click", this.updateCountdown);
          clearTimeout(timer);
        }, 10);
      } else {
        if (document.getElementById("modal-c-c")) {
          document
            .getElementById("modal-c-c")
            .removeEventListener("click", this.updateCountdown);
        }
        this.$emit('closeModal')
      }
    },

    isDisplayModalConfirmCall(val) {
      this.isVisible = val;
      this.isTimerVisible = val;
    }
  },

  methods: {
    onClose() {
      this.isVisible = false;
      this.isTimerVisible = false;
    },

    onConfirm() {
      this.onClose();
      this.trigger();
    },

    updateCountdown() {
      this.isTimerVisible = false;
      const timer = setTimeout(() => {
        this.isTimerVisible = true;
        clearTimeout(timer);
      }, 10);
    },

    updated(event) {
      if (event["1"] === 15) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped>
.MainTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;
}

.container-timer {
  width: 50px;
  height: 50px;
}

.theme--light.v-icon {
  /* color: black; */
}

.container-header__icon {
  /* color: black; */
  border: 2px solid currentColor;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(128, 128, 128, 0.404);
}
</style>
